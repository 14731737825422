import btoa from "btoa";

const REFERRER_CODES = {
  [btoa("Delek")]: "Delek",
  [btoa("Delek Linkedin")]: "Delek Linkedin"
};

export const getReferredByID = code => {
  return REFERRER_CODES[code] || null;
};
