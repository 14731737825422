/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { withPrefix } from "gatsby-link";

function SEO({ title, description, image, url, isArticle, index = true, follow = true }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            defaultTitle: title
            defaultDescription: description
            siteURL
          }
        }
      }
    `
  );

  const { defaultTitle, defaultDescription, siteURL } = site.siteMetadata;

  const titleMaxLength =
    title?.length > 65 ? title.substring(0, 62) + "..." : title;
  const descriptionMaxLength =
    description?.length > 160
      ? description.substring(0, 157) + "..."
      : description;

  const imageAbsPath = !image?.startsWith("http") ? siteURL + image : image;

  const seo = {
    title: titleMaxLength || defaultTitle,
    description: descriptionMaxLength || defaultDescription,
    image: imageAbsPath,
    url: withPrefix(url),
    article: isArticle
  };

  SEO.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    article: PropTypes.bool,
    url: PropTypes.string
  };
  SEO.defaultProps = {
    title: null,
    description: null,
    image: null,
    article: false,
    url: null
  };

  return (
    <Helmet
      link={[
        {
          rel: "stylesheet",
          href:
            // This is a similar and free alternative to Avenir,
            // should the user not be on a Mac.
            "https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap"
        }
      ]}
    >
      <title>{seo.title ? seo.title : "Stockperks"}</title>
      <html lang="en" />
      <meta name="description" content={seo.description} />
      <meta property="og:url" content={seo.url} />
      <meta name={`robots`} content={`${index ? `index`:`noindex`},${follow ? `follow`:`nofollow`}`}/>
      <link rel="canonical" href={seo.url} />

      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:type" content={seo.article ? "article" : "website"} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:site_name" content="Stockperks" />
      {seo.image && <meta property="og:image" content={seo.image} />}

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:site" content="@stockperks" />
      <meta name="twitter:creator" content="@stockperks" />
      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  );
}

export default SEO;
