// Modules
import styled from "styled-components";
import React, { useState, useEffect } from "react";

// Utils
import redirects from "../utils/redirects";
import { windowSize } from "../utils/responsive";

const FallbackImageContainer = styled.img`
  display: block;
  margin: 0 auto;
  width: 50%;

  @media (max-width: ${windowSize.large}px) {
    width: 100%;
  }
`;

const FallbackImage = ({ preset }) => {
  const [fallbackImage, setFallbackImage] = useState(null);

  // Handle fallback image
  useEffect(() => {
    setFallbackImage(preset || redirects.SURVEYS.fallbackImg);
  }, []);

  return <>{fallbackImage && <FallbackImageContainer src={fallbackImage} />}</>;
};

export default FallbackImage;
