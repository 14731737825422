import AppStoreLinks from "./AppStoreLinks";
import React from "react";
import styled from "styled-components";
import SEO from "./SEO";
import StockperksLogoSvg from "../images/stockperks.svg";
import { Link } from "gatsby";


const GetAppBadges = () => {

  return (
    <Container>
      <SEO
        title="Get Stockperks"
        url={"/get-app/"}
        description={
          "Download Stockperks App"
        }
      />
      <Link to="/">
        <StockperksLogo src={StockperksLogoSvg} />
      </Link>
      <AppStoreLinks />
    </Container>
  );
};

export default GetAppBadges;

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  padding: 25px;
`;

const StockperksLogo = styled.img`
  width: 200px;
  margin-bottom: 60px;
`
