// Modules
import React from "react";
import styled from "styled-components";
import { isBrowser } from "react-device-detect"

// Components
import AppStoreBadge from "../images/app-store-badge.svg";
import PlayStoreBadge from "../images/play-store-badge.svg";
import QR from "../images/get-app-qr.svg";

// Constants
import { APP_STORE_LINK, PLAY_STORE_LINK } from "../utils/store-links";
import Analytics from "../utils/tracking";

const StoreButtons = styled.div`
  align-items: center;
  display: flex;
  margin: 10px 0 10px 0;
  > a {
    flex: 1;
    max-width: fit-content;
  }
  @media(max-width: 500px) {
    flex-direction: column;
    > button {
      margin: 0 0 10px 0 !important;
    }
  }
`;

const StoreButton = styled.button`
  background: none;
  border: none;
  height: 50px;
  margin: 0;
  padding: 0;
`

const QRButton = styled(StoreButton)`
  align-items: center;
  background-color: black;
  border-radius: 8px;
  display: inline-flex;
  height: 50px;
  justify-content: center;
  margin-left: 10px;
  width: 50px;
`


const AppStoreLinks = () => {

  const getAppIOS = async () => {
    try{
      await Analytics.track("Download Stockperks iOS Badge");
    } finally {
      window.location.href = APP_STORE_LINK;
    }
  }
  const getAppAndroid = async () => {
    try {
      await Analytics.track("Download Stockperks Android Badge");
    } finally {
      window.location.href = PLAY_STORE_LINK;
    }
  }
  
  return (
    <>
      {/* <MobileView> */}
      <StoreButtons>
        <StoreButton onClick={getAppIOS} style={{ marginRight: 10, cursor: "pointer" }}>
          <img src={AppStoreBadge} alt="AppStore" />
        </StoreButton>
        <StoreButton onClick={getAppAndroid} style={{cursor: "pointer"}}>
          <img src={PlayStoreBadge} alt="GooglePlay" />
        </StoreButton>

        {
          isBrowser && (
            <QRButton>
              <img src={QR} alt="QR" style={{ width: 40 }} />
            </QRButton>
          )
        }
      </StoreButtons>
      {/* </MobileView> */}

      {/* <BrowserView>
        <GetAppForm />
      </BrowserView> */}
    </>
  );
};

export default AppStoreLinks;
