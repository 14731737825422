// Modules
import colors from "../utils/colors";
import React, { useState, useEffect } from "react";
import * as queryString from "query-string";
import {
  isIOS,
  osName,
  isMobile,
  isAndroid,
  osVersion,
  browserName,
  mobileModel,
  mobileVendor,
  browserVersion
} from "react-device-detect";

// Components
import FallbackImage from "../components/FallbackImage";
import GetAppBadges from "../components/GetAppBadges";

// Utils
import { getReferredByID } from "../utils/referrerCodes";

// Constants
import { APP_STORE_LINK, PLAY_STORE_LINK } from "../utils/store-links";
import redirects from "../utils/redirects";
import styled from "styled-components";
import GlobalStyle from "../components/GlobalStyle";
import Analytics from "../utils/tracking";



const GetAppPage = ({ location }) => {
  const [prevUrl, setPrevUrl] = useState(null);

  useEffect(() => {
    setPrevUrl(document.referrer);
    async function trackLanding () {
        await Analytics.track("Stockperks - Get App Page", {
            osName,
            osVersion,
            browserName,
            browserVersion,
            mobileVendor: mobileVendor,
            mobileModel: mobileModel,
            referrerUrl: document.referrer || null
          });
    }
    trackLanding()
  }, []);

  // Handle tracking
  useEffect(() => {
    async function dispatchTrack() {
      // Send data to Segment
      if (isMobile) {
        const { rid } = queryString.parse(location.search);
        await Analytics.track("Text me the App", {
          osName,
          osVersion,
          browserName,
          browserVersion,
          mobileVendor: mobileVendor,
          mobileModel: mobileModel,
          referrerId: getReferredByID(rid),
          referrerUrl: document.referrer || null
        });
      }
    }

    dispatchTrack();
  }, [location]);

  // On Mobile => Redirect to Stores
  if (isIOS || isAndroid) {
    // AppStore
    if (isIOS) window.location.href = APP_STORE_LINK;
    // Google Play
    if (isAndroid) window.location.href = PLAY_STORE_LINK;
    return null;
  }

  // On Desktop => Store Badges or Survey Redirect
  if (prevUrl?.includes(redirects.SURVEYS.path)) {
    return <FallbackImage />;
  }

  return (<>
    <GlobalStyle />
    <GetAppBadges />
  </>);
};

export default GetAppPage;
