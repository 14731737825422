import { browserName, browserVersion, mobileModel, mobileVendor, osName, osVersion } from "react-device-detect";

const Analytics = { track : async (page, opts= null) => {
  if (window.analytics !== undefined && process.env.NODE_ENV === "production") {
    let trackingOptions = {
      osName,
      osVersion,
      browserName,
      browserVersion,
      mobileVendor: mobileVendor,
      mobileModel: mobileModel,
      referrerUrl: document.referrer || null
    }

    if (opts) {
      trackingOptions = {...trackingOptions, ...opts}
    }

    window.analytics.track(page, trackingOptions);

  }
}}

export default Analytics